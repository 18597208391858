.carousel .thumbs-wrapper {
    margin-left: 0;
}

.carousel .thumbs li.thumb {
    max-height: 60px;
}

.carousel .thumb img {
    vertical-align: top;
    height: 50px;
    width: 70px;
    object-fit: cover;
}

.thumbs-wrapper.axis-vertical {
    margin-top: 12px;
    margin-bottom: 0;
}

ul.thumbs.animated {
    max-height: 60px;
}

.carousel .slide img {
    max-height: calc(100vw * 0.7);
}
