.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sold-out:hover {
    background-color: transparent;
}

.carousel .slide img {
    height: 450px; /* change this to whatever you want */
    object-fit: contain;
}

.carousel-outer .carousel .slide {
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .7);
}

#ad-post-tags {
    height: 100px;
}

.dropdown-menu.header-user {
    top: 5px !important;
    left: unset !important;
    right: 0px !important;
    width: 10rem;
}

@media only screen and (max-width: 575px) {
    .dropdown-menu.header-user {
        top: 45px !important;
        left: unset !important;
        right: 0px !important;
        width: 10rem;
        transform: unset !important;
    }
}

/*.dropdown-menu.header-notifications.show {*/
/*  top: 5px !important;*/
/*  left: -220px !important;*/
/*  width: 20rem !important;*/
/*}*/

/*ul .header-notifications {*/
/*  font-size: 15px;*/
/*}*/

/*ul .header-notifications li {*/
/*  line-height: 16px;*/
/*  margin: 10px 0px;*/
/*}*/

.header-widget li button, .header-widget li a {
    justify-content: left;
}
